import { PricingUnitType, ProductCreate } from '@phrospr/lib-models';

export function getNewProduct(): ProductCreate {
    return {
        product_number: null,
        pricing: {
            price_per_unit: null,
            unit_amount: null,
            unit_type: PricingUnitType.Stuk,
        },
        specifications: {
            description: null,
        },
        name: null,
        creation_date: null,
        active: true,
        min_stock: null,
    };
}
