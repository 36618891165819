export enum ProductColumns {
    product_id = 'product_id',
    name = 'name',
    specifications = 'specifications',
    pricing = 'pricing',
    creation_date = 'creation_date',
    product_number = 'product_number',
    active = 'active',
}

export interface ProductAttributes {
    product_id: number;
    name: string;
    specifications: any;
    pricing: any;
    creation_date: Date | null;
    product_number: string | null;
    active: boolean | null;
    min_stock: number | null;
}

export type ProductOptionalAttributes = 'product_id' | 'creation_date' | 'product_number' | 'active';
export interface ProductCreate {
    product_id?: number;
    name: string;
    specifications: any;
    pricing: any;
    creation_date?: Date | null;
    product_number?: string | null;
    active?: boolean | null;
    min_stock: number | null;
}
