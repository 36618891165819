import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { InventoryFilterProps, InventoryFilterState } from './InventoryFilter.types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export class InventoryFilter extends React.Component<InventoryFilterProps, InventoryFilterState> {
    constructor(props: InventoryFilterProps) {
        super(props);
        this.state = {
            inventorySearchFilter: {
                product_id: null,
                product_name: null,
                product_number: null,
                location_id: null,
                location_name: null,
                from_created_date: null,
                to_created_date: null,
                quantity_on_hand: null,
                // movement_type: [MovementType.COUNT, MovementType.INBOUND, MovementType.OUTBOUND],
            },
        };
        this.props.onChange(this.state.inventorySearchFilter);
    }

    updateSearch = async () => {
        this.props.onChange(this.state.inventorySearchFilter);
    };

    resetFilters = async () => {
        await this.setState({
            inventorySearchFilter: {
                product_id: null,
                product_name: null,
                product_number: null,
                location_id: null,
                location_name: null,
                from_created_date: null,
                to_created_date: null,
                quantity_on_hand: null,
                // movement_type: [MovementType.COUNT, MovementType.INBOUND, MovementType.OUTBOUND],
            },
        });
        console.log(this.state);
        this.updateSearch();
    };

    render() {
        return (
            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid spacing={2} container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="inventory-id-search"
                                label="Zoek op product number"
                                variant="standard"
                                value={this.state.inventorySearchFilter.product_number ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            inventorySearchFilter: {
                                                ...this.state.inventorySearchFilter,
                                                product_number: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="name-search"
                                label="Zoek op product naam"
                                variant="standard"
                                value={this.state.inventorySearchFilter.product_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            inventorySearchFilter: {
                                                ...this.state.inventorySearchFilter,
                                                product_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                size="small"
                                id="zip-code-search"
                                label="Zoek op locatie naam"
                                variant="standard"
                                value={this.state.inventorySearchFilter.location_name ?? ''}
                                onChange={event =>
                                    this.setState(
                                        {
                                            inventorySearchFilter: {
                                                ...this.state.inventorySearchFilter,
                                                location_name: event.target.value,
                                            },
                                        },
                                        () => this.updateSearch(),
                                    )
                                }
                            />
                        </Grid>
                        {/*can get added on stock movements filter*/}
                        {/*<Grid item xs>*/}
                        {/*    <TextField*/}
                        {/*        size="small"*/}
                        {/*        id="sold-by-search"*/}
                        {/*        label="Zoek op user"*/}
                        {/*        variant="standard"*/}
                        {/*        value={this.state.inventorySearchFilter.created_by_user_name ?? ''}*/}
                        {/*        onChange={event =>*/}
                        {/*            this.setState(*/}
                        {/*                {*/}
                        {/*                    inventorySearchFilter: {*/}
                        {/*                        ...this.state.inventorySearchFilter,*/}
                        {/*                        created_by_user_name: event.target.value,*/}
                        {/*                    },*/}
                        {/*                },*/}
                        {/*                () => this.updateSearch(),*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs>*/}
                        {/*    <FormControl sx={{ m: 1, width: 180 }}>*/}
                        {/*        <InputLabel id="demo-multiple-checkbox-label">Movement type</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            size="small"*/}
                        {/*            labelId="movement-type-checkbox"*/}
                        {/*            id="movement-type-multiple-checkbox"*/}
                        {/*            label={'Movement type'}*/}
                        {/*            multiple*/}
                        {/*            value={this.state.inventorySearchFilter.movement_type}*/}
                        {/*            onChange={event =>*/}
                        {/*                this.setState(*/}
                        {/*                    {*/}
                        {/*                        inventorySearchFilter: {*/}
                        {/*                            ...this.state.inventorySearchFilter,*/}
                        {/*                            movement_type: event.target.value as MovementType[],*/}
                        {/*                        },*/}
                        {/*                    },*/}
                        {/*                    () => this.updateSearch(),*/}
                        {/*                )*/}
                        {/*            }*/}
                        {/*            input={<OutlinedInput label="status" />}*/}
                        {/*            renderValue={(selected: string[]) => `${selected.length} geselecteerd`}*/}
                        {/*            MenuProps={MenuProps}*/}
                        {/*        >*/}
                        {/*            {Object.keys(MovementType).map(movement => (*/}
                        {/*                <MenuItem key={movement} value={MovementType[movement]}>*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={this.state.inventorySearchFilter.movement_type.includes(*/}
                        {/*                            MovementType[movement],*/}
                        {/*                        )}*/}
                        {/*                    />*/}
                        {/*                    <ListItemText primary={MovementType[movement]} />*/}
                        {/*                </MenuItem>*/}
                        {/*            ))}*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        <Grid>
                            <Button size={'small'} startIcon={<CloseIcon />} onClick={() => this.resetFilters()}>
                                Clear filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export default InventoryFilter;
