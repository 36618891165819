"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIInventoryExtendedEntity = void 0;
const ilocations_entity_1 = require("./ilocations-entity");
const iproduct_entity_1 = require("./iproduct-entity");
const istock_movement_entity_1 = require("./istock-movement-entity");
const iuser_entity_1 = require("./iuser-entity");
function parseIInventoryExtendedEntity(data) {
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, ilocations_entity_1.parseILocationsEntity)(data.location);
    if (data.stock_movements !== null && typeof data.stock_movements === 'object')
        data.stock_movements = (0, istock_movement_entity_1.parseIStockMovementEntity)(data.stock_movements);
    if (data.product !== null && typeof data.product === 'object')
        data.product = (0, iproduct_entity_1.parseIProductEntity)(data.product);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    return data;
}
exports.parseIInventoryExtendedEntity = parseIInventoryExtendedEntity;
