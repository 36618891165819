import { Autocomplete, Box, CircularProgress, Stack, TextField } from '@mui/material';
import { LocationApi, RequestSort } from '@phrospr/api-backend';
import React from 'react';
import * as toastr from 'toastr';
import './LocationSearch.scss';
import { AutoCompleteChip } from './LocationSearch.style';
import { ILocationsEntity } from '@phrospr/lib-models';
import { LocationSearchProps, LocationSearchState } from './LocationSearch.types';

export class LocationSearch extends React.Component<LocationSearchProps, LocationSearchState> {
    constructor(props: LocationSearchProps) {
        super(props);
        this.state = { open: false, loading: false, locationOptions: [] };
        this.loadLocationOptions();
    }

    async loadLocationOptions(searchValue?: string) {
        this.setState({ loading: true });
        try {
            const locations = (
                await LocationApi.listLocations({
                    filter: {
                        location_name: searchValue?.toString() ?? undefined,
                    },
                    limit: 10,
                    offset: 0,
                    sort: {
                        column: 'location_id',
                        order: RequestSort.ASC,
                    },
                })
            ).data.records;
            this.setState({ locationOptions: locations });
        } catch (e) {
            console.log(e);
            toastr.error('something went wrong');
        }
        this.setState({ loading: false });
    }

    selectLocation(values: (string | ILocationsEntity)[]) {
        if (values.length) {
            const value = values[0];
            if (typeof value === 'string') return;
            return this.props.onLocationChange(value);
        }
        this.props.onLocationChange(values as any);
    }

    render() {
        return (
            <div>
                <Autocomplete
                    sx={{ width: 300 }}
                    onFocus={() => this.setState({ open: true })}
                    open={this.state.open}
                    onOpen={() => this.setState({ open: true })}
                    onClose={() => this.setState({ open: false })}
                    isOptionEqualToValue={(option, value) => option.location_id === value.location_id}
                    getOptionLabel={(l: ILocationsEntity) => `${l.location_name ?? ''}  (ID: ${l.location_id ?? ''})`}
                    renderOption={(props, option, extra) => (
                        <li {...props}>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                sx={{ maxWidth: '100%' }}
                                alignItems="center"
                            >
                                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} component="span">{`${
                                    option.location_id
                                }, ${option.location_name ?? ''}`}</Box>
                            </Stack>
                        </li>
                    )}
                    options={this.state.locationOptions}
                    loading={this.state.loading}
                    onChange={(event, value) => this.selectLocation(value)}
                    onInputChange={(_, value) => this.loadLocationOptions(value)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Zoek location..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}

export default LocationSearch;
