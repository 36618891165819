"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * @phrospr/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.7.14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseILocationSearchEntity = void 0;
const iactivity_search_entity_sort_1 = require("./iactivity-search-entity-sort");
const ilocation_search_show_entity_1 = require("./ilocation-search-show-entity");
const ilocations_search_filter_entity_1 = require("./ilocations-search-filter-entity");
function parseILocationSearchEntity(data) {
    if (data.sort !== null && typeof data.sort === 'object')
        data.sort = (0, iactivity_search_entity_sort_1.parseIActivitySearchEntitySort)(data.sort);
    if (data.filter !== null && typeof data.filter === 'object')
        data.filter = (0, ilocations_search_filter_entity_1.parseILocationsSearchFilterEntity)(data.filter);
    if (data.show !== null && typeof data.show === 'object')
        data.show = (0, ilocation_search_show_entity_1.parseILocationSearchShowEntity)(data.show);
    return data;
}
exports.parseILocationSearchEntity = parseILocationSearchEntity;
